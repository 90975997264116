/*
 * [Modified] Modern CSS Reset
 * @link https://github.com/hankchizljaw/modern-css-reset
*/
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p,
li,
h1,
h2,
h3,
h4 {
  word-break: break-word;
}

html,
body {
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.5;
  text-rendering: optimizeSpeed;
}

/* Make images easier to work with */
img {
  display: block;
  max-width: 100%;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
  html {
    scroll-behavior: initial;
  }
}
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #121212;
  color: rgb(216.75, 216.75, 216.75);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

main,
main.tdbc-container {
  padding-bottom: 5vh;
}

footer {
  margin-top: auto;
}

article {
  max-width: 80ch;
}
article > img {
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 2vh;
}

.tdbc-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 120ch;
  margin-right: auto;
  margin-left: auto;
  padding: 1rem;
}

.tdbc-column-container {
  display: grid;
  grid-gap: 3em;
  grid-template-columns: repeat(auto-fit, minmax(25ch, 1fr));
}

ul.tdbc-column-container {
  padding-left: 0;
}

.tdbc-section {
  width: 100%;
}
.tdbc-section--padded {
  padding-top: 10vh;
  padding-bottom: 10vh;
}
.tdbc-section *:last-child {
  margin-bottom: 0;
}

.tdbc-hero {
  display: grid;
  min-height: 40vh;
  place-content: center;
  padding-top: 5vh;
  text-align: center;
}

.tdbc-row {
  display: grid;
  grid-gap: 1rem;
}
@media (min-width: 80ch) {
  .tdbc-row {
    grid-auto-flow: column;
    grid-auto-columns: max-content;
  }
}
.tdbc-row--center-content {
  justify-content: center;
}
.tdbc-row--center-alignitems {
  align-items: center;
}

hr {
  margin-top: 4rem;
  margin-bottom: 4rem;
  border: 1px solid black;
}

.avatar {
  max-height: 400px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}

#avanti-indietro {
  text-align: center;
  margin-top: 3em;
  font-size: large;
  font-family: Teko;
}

/*#tdbc-siteid {
  font-size: theme.$tdbc-h3-font-size;
  font-family: Teko;
  text-decoration: none;
}*/
.tdbc-sitenav {
  background-color: black;
  font-family: Teko;
}
.tdbc-sitenav__container, .tdbc-sitenav__navlist {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.tdbc-sitenav__container {
  justify-content: space-between;
  width: 100%;
  max-width: 120ch;
  margin: 0 auto;
  padding: 0.25rem 0.5rem;
}
.tdbc-sitenav__navlist li + li {
  margin-left: 0.5rem;
}
.tdbc-sitenav,
.tdbc-sitenav a:not(.tdbc-button) {
  color: #eceef7;
}
.tdbc-sitenav a:not(.tdbc-button):focus {
  border-radius: 8px;
  outline-color: transparent;
  outline-style: solid;
  box-shadow: 0 0 1px 2px #eceef7;
}
.tdbc-sitenav a {
  text-decoration: none;
  padding: 0.2vh;
}
.tdbc-sitenav a:hover {
  background-color: white;
  color: black;
}

h1,
.tdbc-h1 {
  font-weight: 500;
  font-family: Teko;
  line-height: 1.15;
  margin-top: auto;
  margin-bottom: 0.4em;
}

h2,
.tdbc-h2 {
  font-weight: 500;
  font-family: Teko;
  line-height: 1.15;
  margin-top: auto;
  margin-bottom: 0.4em;
}

h3,
.tdbc-h3 {
  font-weight: 500;
  font-family: Teko;
  line-height: 1.15;
  margin-top: auto;
  margin-bottom: 0.4em;
}

h4,
.tdbc-h4 {
  font-weight: 500;
  font-family: Teko;
  line-height: 1.15;
  margin-top: auto;
  margin-bottom: 0.4em;
}

h1,
.tdbc-h1 {
  font-size: 4.5rem;
  font-size: min(max(3rem, 5vw), 4.5rem);
}

h2,
.tdbc-h2 {
  font-size: 3.5rem;
  font-size: min(max(2.5rem, 5vw), 3.5rem);
}

h3,
.tdbc-h3 {
  font-size: 2rem;
}

h4,
.tdbc-h4 {
  font-size: 1.45rem;
  color: rgb(147.625, 147.625, 147.625);
}

p,
li {
  font-size: 1.35rem;
}

p {
  margin: 0 0 1rem;
}

article > ul > li + li,
article > ol > li + li {
  margin-top: 1rem;
}
article * + h2 {
  margin-top: 1rem;
}
article * + h3 {
  margin-top: 2rem;
}

/*strong {
  font-weight: theme.$tdbc-strong-font-weight;
}*/
a:not(.tdbc-button) {
  color: #ffffff;
}

a:hover {
  color: #fdeb37;
}

code:not([class*=language]) {
  display: inline-flex;
  padding: 0.15em;
  background-color: #eceef7;
  color: rgb(147.625, 147.625, 147.625);
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  border: 1px solid rgb(201.3125, 201.3125, 201.3125);
  border-radius: 2px;
  font-size: 0.75em;
  line-height: 1.1;
}

pre code:not([class*=language]) {
  width: 100%;
  max-width: calc(100vw - 2rem);
  overflow-x: auto;
}

blockquote {
  margin-left: 0;
  margin-right: 0;
  padding-left: 1.5rem;
  border-left: 2px solid black;
  color: rgb(147.625, 147.625, 147.625);
}
blockquote,
blockquote p {
  font-size: 1.45rem;
}
blockquote + * {
  margin-top: 3rem;
}
blockquote :last-child {
  margin-bottom: 0;
}

.tdbc-lead {
  font-size: 1.45rem;
  letter-spacing: 0.01em;
  margin-bottom: 1em;
  line-height: 1.3;
}

.tdbc-list-unstyled {
  list-style: none;
  padding: 0;
}

time {
  text-align: center;
  font-size: smaller;
  color: #868686;
  padding: 2vh;
}

.tdbc-button {
  display: inline-flex;
  align-items: center;
  align-self: start;
  justify-content: center;
  min-width: 10ch;
  min-height: 44px;
  padding: 0.25em 1em;
  transition: 180ms ease-in-out;
  transition-property: background, border;
  border-radius: 8px;
  background-color: black;
  color: #fff;
  font-size: 1.125rem;
  line-height: 1.1;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}
@media screen and (-ms-high-contrast: active) {
  .tdbc-button {
    border: 2px solid currentColor;
  }
}
.tdbc-button:focus {
  outline-color: transparent;
  outline-style: solid;
  box-shadow: 0 0 0 3px rgb(178.5, 178.5, 178.5);
}
.tdbc-button--small {
  min-height: 34px;
  font-size: 1rem;
}
.tdbc-button--center {
  align-self: center;
  margin-right: auto;
  margin-left: auto;
}
.tdbc-button__icon {
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.25em;
}
.tdbc-button__icon--end {
  margin-right: 0;
  margin-left: 0.25em;
}
.tdbc-button__icon svg {
  width: 100%;
  height: 100%;
  fill: currentColor;
}
.tdbc-button-outlined {
  border: 2px solid #ffffff;
  background-color: transparent;
  color: #ffffff;
}
.tdbc-button-outlined:hover, .tdbc-button-outlined:focus {
  background-color: white;
}
.tdbc-button--secondary {
  background-color: black;
}
.tdbc-button--secondary:focus {
  box-shadow: 0 0 0 3px black;
}
.tdbc-button-outlined--secondary {
  border-color: rgb(87.975, 65.025, 65.025);
  color: black;
}
.tdbc-button-outlined--secondary:focus {
  box-shadow: 0 0 0 3px black;
}
.tdbc-button-outlined--secondary:hover, .tdbc-button-outlined--secondary:focus {
  background-color: rgb(229.5, 229.5, 229.5);
}
.tdbc-button--light {
  background-color: #eceef7;
  color: rgba(0, 0, 0, 0.87);
}
.tdbc-button--light:focus {
  box-shadow: 0 0 0 3px rgb(134.0333333333, 146.7666666667, 204.0666666667);
}
.tdbc-button-outlined--light {
  border-color: rgb(240.86, 242.5654545455, 250.24);
  color: #eceef7;
}
.tdbc-button-outlined--light:focus {
  box-shadow: 0 0 0 3px rgb(134.0333333333, 146.7666666667, 204.0666666667);
}
.tdbc-button-outlined--light:hover, .tdbc-button-outlined--light:focus {
  background-color: rgba(236, 238, 247, 0.15);
}
.tdbc-button--gray {
  background-color: rgb(147.625, 147.625, 147.625);
}
.tdbc-button--gray:focus {
  box-shadow: 0 0 0 3px rgb(103.3375, 103.3375, 103.3375);
}
.tdbc-button-outlined--gray {
  border-color: rgb(191.111875, 168.563125, 168.563125);
  color: rgb(147.625, 147.625, 147.625);
}
.tdbc-button-outlined--gray:focus {
  box-shadow: 0 0 0 3px rgb(103.3375, 103.3375, 103.3375);
}
.tdbc-button-outlined--gray:hover, .tdbc-button-outlined--gray:focus {
  background-color: rgb(244.2625, 244.2625, 244.2625);
}

.tdbc-button__icon,
.tdbc-icon-button {
  display: inline-flex;
  position: relative;
  width: 1em;
  height: 1em;
  padding: 0;
  border: none;
  background: transparent;
  font-size: 1.5rem;
  cursor: pointer;
}
.tdbc-button__icon svg,
.tdbc-icon-button svg {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
}

.tdbc-icon-button svg {
  fill: currentColor;
  opacity: 0.7;
}
.tdbc-icon-button:focus, .tdbc-icon-button:hover {
  outline: none;
}
.tdbc-icon-button:focus svg, .tdbc-icon-button:hover svg {
  opacity: 1;
}
.tdbc-icon-button:focus::after {
  position: absolute;
  top: -0.3em;
  right: -0.3em;
  bottom: -0.3em;
  left: -0.3em;
  border: 1px solid currentColor;
  border-radius: 50%;
  content: "";
}

.tdbc-card {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  background-color: rgb(5, 5, 5);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.18);
  /* stylelint-disable selector-max-type, selector-no-qualifying-type */
}
.tdbc-card__title {
  margin-bottom: 1rem;
  font-size: 1.45rem;
  line-height: 1.15;
  text-decoration: none;
}
.tdbc-card a.tdbc-card__title::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 180ms box-shadow ease-in;
  border-radius: 8px;
  content: "";
}
.tdbc-card a.tdbc-card__title:focus {
  outline: none;
}
.tdbc-card a.tdbc-card__title:focus::before {
  box-shadow: 0 0 0 3px #ffffff;
}
.tdbc-card__content {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-grow: 1;
  padding: 14px;
}
.tdbc-card__content a:last-child {
  align-self: start;
  margin-top: auto;
}
.tdbc-card__content p {
  position: relative;
  font-size: smaller;
  margin: 0 0 1rem;
  z-index: 1;
}
.tdbc-card__content > ul {
  list-style-type: disc;
  padding-left: 1rem;
}
.tdbc-card__content > ul li + li {
  margin-top: 0.5rem;
}
.tdbc-card:hover a.tdbc-card__title::before {
  box-shadow: 0 0 0 3px #ffffff;
}
.tdbc-card img:first-child {
  object-fit: cover;
  max-height: 10em;
  border-radius: 8px 8px 0 0;
}
.tdbc-card--outlined {
  border: 1px solid rgb(201.3125, 201.3125, 201.3125);
  background-color: transparent;
  box-shadow: none;
}

/**
 * prism.js tomorrow night eighties for JavaScript, CoffeeScript, CSS and HTML
 * Based on https://github.com/chriskempson/tomorrow-theme
 * @author Rose Pritchard
 */
code[class*=language-],
pre[class*=language-] {
  color: #ccc;
  background: none;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

/* Code blocks */
pre[class*=language-] {
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
}

:not(pre) > code[class*=language-],
pre[class*=language-] {
  background: #2d2d2d;
}

/* Inline code */
:not(pre) > code[class*=language-] {
  padding: 0.1em;
  border-radius: 0.3em;
  white-space: normal;
}

.token.comment,
.token.block-comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #999;
}

.token.punctuation {
  color: #ccc;
}

.token.tag,
.token.attr-name,
.token.namespace,
.token.deleted {
  color: #e2777a;
}

.token.function-name {
  color: #6196cc;
}

.token.boolean,
.token.number,
.token.function {
  color: #f08d49;
}

.token.property,
.token.class-name,
.token.constant,
.token.symbol {
  color: #f8c555;
}

.token.selector,
.token.important,
.token.atrule,
.token.keyword,
.token.builtin {
  color: #cc99cd;
}

.token.string,
.token.char,
.token.attr-value,
.token.regex,
.token.variable {
  color: #7ec699;
}

.token.operator,
.token.entity,
.token.url {
  color: #67cdcc;
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

.token.inserted {
  color: green;
}

.tdbc-content-maxlength {
  max-width: 80ch;
}

.tdbc-anchor {
  display: inline-block;
  margin-left: 0.25em;
  transition: 180ms opacity ease-in-out;
  opacity: 0.6;
  font-size: 0.65em;
  vertical-align: top;
}
.tdbc-anchor:hover {
  opacity: 1;
}

.tdbc-text-align--left {
  text-align: left;
}

.tdbc-text-align--center {
  text-align: center;
}
.tdbc-text-align--center > a {
  align-self: center !important;
}

.tdbc-text-align--right {
  text-align: right;
}

.tdbc-background--primary {
  background-color: #ffffff;
}

.tdbc-background--secondary {
  background-color: black;
}

.tdbc-background--background {
  background-color: #121212;
}

.tdbc-background--gray {
  background-color: rgb(147.625, 147.625, 147.625);
}

.tdbc-background--light {
  background-color: #eceef7;
}

.tdbc-ink--primary,
a.tdbc-ink--primary {
  color: #ffffff;
}

.tdbc-ink--primary > a:not(.tdbc-button) {
  color: inherit;
}

.tdbc-ink--secondary,
a.tdbc-ink--secondary {
  color: black;
}

.tdbc-ink--secondary > a:not(.tdbc-button) {
  color: inherit;
}

.tdbc-ink--text,
a.tdbc-ink--text {
  color: rgb(216.75, 216.75, 216.75);
}

.tdbc-ink--text > a:not(.tdbc-button) {
  color: inherit;
}

.tdbc-ink--gray,
a.tdbc-ink--gray {
  color: rgb(147.625, 147.625, 147.625);
}

.tdbc-ink--gray > a:not(.tdbc-button) {
  color: inherit;
}

.tdbc-ink--light,
a.tdbc-ink--light {
  color: #eceef7;
}

.tdbc-ink--light > a:not(.tdbc-button) {
  color: inherit;
}

.tdbc-ink--dark,
a.tdbc-ink--dark {
  color: rgba(0, 0, 0, 0.87);
}

.tdbc-ink--dark > a:not(.tdbc-button) {
  color: inherit;
}

.tdbc-mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.tdbc-my-sm {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.tdbc-mt-sm {
  margin-top: 1rem;
}

.tdbc-my-md {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.tdbc-mt-md {
  margin-top: 3rem;
}

.tdbc-my-lg {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.tdbc-mt-lg {
  margin-top: 6rem;
}

.tdbc-my-xl {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.tdbc-mt-xl {
  margin-top: 10rem;
}

.tdbc-my-auto {
  margin-top: "auto";
  margin-bottom: "auto";
}

.tdbc-mt-auto {
  margin-top: "auto";
}

.tdbc-mb-none {
  margin-bottom: 0;
}

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Force table to not be like tables anymore */
  table, thead, tbody, th, td, tr {
    display: block;
  }
  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  td {
    /* Behave like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }
  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }
}
table {
  margin-bottom: 1em;
  overflow-x: auto;
  width: 100%;
  font-size: 15px;
  border-collapse: collapse;
}
table + table {
  margin-top: 1em;
}

thead {
  color: white;
  background-color: black;
  border-bottom: 2px solid rgb(63.75, 63.75, 63.75);
}

th {
  padding: 0.5em;
  font-weight: bold;
  text-align: left;
}

td {
  padding: 0.5em;
  border-bottom: 1px solid rgb(114.75, 114.75, 114.75);
}

tr,
td,
th {
  vertical-align: middle;
}

.resumeAvatar {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  width: 50%;
}

.comment__avatar-wrapper {
  float: left;
  width: 60px;
  height: 60px;
}

.comment__avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.comment__content-wrapper {
  float: right;
  width: calc(100% - 60px);
}

.comment__author {
  margin: 0;
  font-style: bold;
}
.comment__author a {
  text-decoration: none;
}

.comment__date {
  font-size: 0.7em;
  font-style: italic;
}

.comment__hr {
  margin-top: 6px;
  margin-bottom: 6px;
}

.comment__body {
  font-size: 0.9em;
}

.social {
  font-family: Teko;
  font-size: 170%;
  text-align: center;
  padding: 2px;
}

.social ul {
  list-style: none;
}

.social li {
  display: inline-block;
}

.social svg {
  width: 96px;
}

.twitter {
  fill: #00acee;
}

.twitter:hover {
  fill: white;
}

.instagram {
  fill: #E1306C;
}

.instagram:hover {
  fill: white;
}

.github {
  fill: rgb(161, 161, 161);
}

.github:hover {
  fill: white;
}

.lastfm {
  fill: red;
}

.lastfm:hover {
  fill: white;
}

.rss {
  fill: orange;
}

.rss:hover {
  fill: white;
}

.bandiere {
  font-family: Teko;
  font-size: 170%;
  text-align: center;
  padding: 2px;
}

.bandiere ul {
  list-style: none;
}

.bandiere li {
  display: inline-block;
  margin: 0px 5px 5px 5px;
}

.bandiere svg {
  width: 96px;
}

.italia:hover {
  fill: white;
}

.uk:hover {
  fill: white;
}

.titolo {
  animation: glow 2500ms linear infinite 2000ms;
}

@keyframes glow {
  40% {
    text-shadow: 0 0 8px #fff;
  }
}
.share-page {
  text-align: center;
  background: #ffffff;
  color: black;
  padding: 8px 15px;
  border-radius: 5px;
  margin-top: 15px;
}
.share-page a {
  font-weight: 700;
  color: rgb(0, 0, 0);
  margin-left: 10px;
}
.share-page a:hover {
  background-color: black;
  color: white;
}

@font-face {
  font-family: "Baloo 2";
  font-style: normal;
  font-weight: normal;
  src: local("Baloo 2 Regular"), local("Baloo-2-Regular"), url("../fonts/baloo-2-v1-latin-regular.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Baloo 2";
  font-style: normal;
  font-weight: 500;
  src: local("Baloo 2 Medium"), local("Baloo-2-Medium"), url("../fonts/baloo-2-v1-latin-500.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Teko";
  font-style: normal;
  font-weight: normal;
  src: local("Teko"), local("Teko"), url("../fonts/teko.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "PressStart";
  font-style: normal;
  font-weight: normal;
  src: local("PressStart"), local("PressStart"), url("../fonts/PressStart2P.woff2") format("woff");
  font-display: swap;
}
